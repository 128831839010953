.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

/*
@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}
*/

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 150px;
  width: 150px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.harrisburg-logo {
  /* background-image: url("./assets/harrisburg-logo.png"); */
  background-image: url("./assets/hs-final-logos-900-400-300x119.png");
  /*background-size: 177px 70px;*/
  width: 9rem;
  height: 5rem;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10px;
}